import React, { createRef } from "react";
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Segment,
} from "semantic-ui-react";
import store from "store";
import ACL_RELATIONSHIPS from "../../../acl-relationships";
import BaseTable from "../../../components/BaseTable";
import { checkIsAuthorized } from "../../../components/helpers";
import withRoleCheck from "../../../components/hocs/withRoleCheck";
import { SYSTEM_ROLES } from "../../../constants/Constants";
import setPageTitle from "../../../helpers/title";
import AuthService from "../../../services/Auth";
import UserService from "../../../services/User";
import ConfirmModal from "./ConfirmModal";
import UserModal from "./UserModal";
import EditRoleModal from "./EditRoleModal";

const AddUserButton = withRoleCheck(Button, [
  ACL_RELATIONSHIPS.adminUser.create,
]);

class UsersTable extends BaseTable {
  constructor(props) {
    super(props);

    this.queryMethod = UserService.getUsersAsAdmin;

    this.userModal = createRef();
    this.confirmModal = createRef();
    this.editRoleModal = createRef();

    this.state = {
      ...this.state,
      enableSearch: true,
      enableSettings: false,
      tableName: "users_table",
      noDataText: "No Users",
      canResetPassword: checkIsAuthorized([
        ACL_RELATIONSHIPS.adminUserResetPassword.create,
      ]),
      canEditUser: checkIsAuthorized([ACL_RELATIONSHIPS.adminUser.edit]),
      canDeleteUser: checkIsAuthorized([ACL_RELATIONSHIPS.adminUser.delete]),
      exportTableName: "UsersTable",
      userIsAdmin: false,
      createButton: (
        <AddUserButton
          size="tiny"
          content="New User"
          className="item-adder"
          onClick={() => this.userModal.current.open()}
        />
      ),
    };
  }

  async componentDidMount() {
    setPageTitle("Users");
    if (AuthService.isLoggedIn()) {
      await this.fetchData();
    }
    this.setState({ userIsAdmin: await UserService.isAdmin() });
  }

  setColumns = () => {
    const columns = [
      {
        Header: () => (
          <Checkbox
            onChange={this.onSelectAll}
            checked={this.state.allSelected}
          />
        ),
        resizable: false,
        sortable: false,
        width: 40,
        headerClassName: "centered",
        className: "centered",
        Cell: ({ original: { id, full_name } }) => (
          <Checkbox
            onChange={this.handleChange}
            name={full_name}
            id={id}
            checked={this.state.checked[id]}
          />
        ),
      },
      {
        Header: "ID",
        accessor: "id",
        width: 50,
        Cell: props => <p style={{ fontWeight: "bold" }}>{props.value}</p>,
      },
      {
        Header: "Name",
        accessor: "full_name",
        headerClassName: "padded",
        className: "padded",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "External User?",
        accessor: "external",
        Cell: ({ value }) => (value ? "Yes" : null),
      },
      {
        Header: "External User Group",
        accessor: "group",
        Cell: ({ value }) => value.name,
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ value: role }) => role.name,
      },
    ];
    this.initTableSettings(columns);
  };

  actionsDropdown() {
    const disableActions = this.state.checkedArr.length === 0;
    const {
      checkedArr,
      canResetPassword,
      canDeleteUser,
      canEditUser,
      userIsAdmin,
    } = this.state;
    const userLabel = checkedArr.length === 1 ? "user" : "users";
    const checkedContainsAdmin = this.state.rows
      .filter(u => checkedArr.includes(u.id))
      .some(u => u.role.id === SYSTEM_ROLES.ADMIN);
    const confirmableActions = [
      canResetPassword && {
        title: `Reset password for ${userLabel}`,
        action: `Reset Password${checkedArr.length === 1 ? "" : "s"}`,
        actionLong: "reset the password for",
        onConfirm: this.confirmResetPassword,
      },
      canDeleteUser && {
        title: `Delete ${userLabel}`,
        action: "Delete",
        actionLong: "delete",
        onConfirm: this.confirmDelete,
      },
    ].filter(i => !!i);

    if (
      (canEditUser || canDeleteUser || canResetPassword) &&
      (!checkedContainsAdmin || userIsAdmin)
    ) {
      return (
        <Dropdown
          text="Actions"
          className="button mini"
          disabled={disableActions}
        >
          <Dropdown.Menu direction="left">
            {checkedArr.length === 1 && canEditUser && (
              <Dropdown.Item
                text="Edit User"
                onClick={() =>
                  this.userModal.current.open(this.getSelectedUser())
                }
              />
            )}
            {checkedArr.length > 1 && canEditUser && (
              <Dropdown.Item
                text="Edit User Roles"
                onClick={() => this.editRoleModal.current.open(checkedArr)}
              />
            )}
            {confirmableActions.map((action, index) => (
              <Dropdown.Item
                key={index}
                text={action.title}
                onClick={() =>
                  this.confirmModal.current.open({
                    userIds: checkedArr,
                    ...action,
                  })
                }
              />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  }

  renderConfirm = () => (
    <ConfirmModal
      ref={this.confirmModal}
      confirmButton={({ action }) => ({
        content: action,
        color: action === "Delete" ? "red" : undefined,
        disabled: !this.checkCanDelete() && action === "Delete",
      })}
      content={({ actionLong, userIds }) => {
        const [preLabel, boldLabel, postLabel] =
          userIds.length === 1
            ? ["user ", this.getSelectedUser().full_name]
            : ["", userIds.length, " users"];
        return !this.checkCanDelete() && actionLong === "delete" ? (
          <>
            <strong>You cannot delete yourself</strong>
          </>
        ) : (
          <>
            You are about to {actionLong} {preLabel}
            <strong>{boldLabel}</strong>
            {postLabel}. Are you sure you want to proceed?
          </>
        );
      }}
    />
  );

  checkCanDelete = () => {
    if (this.state.checkedArr.includes(store.get("userAuth").id)) {
      return false;
    } else {
      return true;
    }
  };

  confirmDelete = async ({ userIds }) => {
    for (const userId of userIds) {
      await UserService.deleteUser(userId);
    }
    this.fetchData();
  };

  confirmResetPassword = async ({ userIds }) => {
    for (const userId of userIds) {
      await UserService.resetPassword(userId);
    }
  };

  getSelectedUser = () => {
    const { checkedArr, rows } = this.state;
    if (checkedArr.length === 0) {
      return null;
    }
    return rows.find(({ id }) => id === checkedArr[0]);
  };

  render = () => {
    return (
      <Container fluid className="admin route" {...this.props}>
        <Segment>
          <div className="content">
            <div className="users-table">
              <UserModal ref={this.userModal} onSuccess={this.fetchData} />
              <EditRoleModal
                ref={this.editRoleModal}
                onSuccess={this.fetchData}
              />
              {this.renderConfirm()}
              {/*{this.renderHeader()}*/}
              {this.renderTable()}
            </div>
          </div>
        </Segment>
      </Container>
    );
  };
}

export default UsersTable;
